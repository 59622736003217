import React, { useEffect } from "react";
import FooterSmall from "../component/FooterSmall.js";
import Navbar from "../component/Navbar.js";
import "./PrivacyPage.css";

const ConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-page">
      <Navbar isInnerPage={true} />
      <div className="privacy-container privacy-content">
        <section className="privacy-section">
          <h1 className="privacy-title">
            AVISO LEGAL : Condiciones generales de uso
          </h1>
          <p className="privacy-text">
            Estas son las Condiciones Generales de Uso (“Condiciones de Uso”)
            que regulan la utilización del sitio Web y de la App (“Sitio”) que
            SLEEPZZONE pone a disposición del Usuario. Las Condiciones de Uso
            junto con el Aviso Legal, la Política de Privacidad y la Política de
            Cookies constituyen en su conjunto los términos y condiciones que
            rigen el acceso y uso de la parte pública y privada del Sitio
            ("Términos y Condiciones del Uso").
          </p>

          <p className="privacy-text">
            <br></br>
            El acceso y utilización del Sitio por parte del Usuario supone que
            ha leído los “Términos y Condiciones de Uso” así cómo el Aviso
            Legal, la Política de Privacidad y la Política de Cookies, los
            acepta en su totalidad y se obliga a cumplirlos por completo
            incluyendo el consentimiento explicito y autorizo expresamente la
            cesión y venta nacional y/o internacional de los datos recabados
            según se indica en la Política de Privacidad.
          </p>
          <p className="privacy-text">
            <br></br>
            Información básica sobre protección de datos.
          </p>
          <p className="privacy-text">
            <br></br>
            Responsable del tratamiento: SLEEPZZONE LAND SL
          </p>
          <p className="privacy-text">
            <br></br>
            Finalidad: A título enunciativo el diseño, valoración, diagnóstico,
            tratamiento, seguimiento, evaluación, formación, I+D+i,
            comunicación, divulgacion, obra social, etc.. del sueño a nivel
            particular o colectivo.
          </p>
          <p className="privacy-text">
            <br></br>
            Legitimación para el tratamiento: Consentimiento explícito del
            interesado otorgado al solicitar la inscripción.
          </p>
          <p className="privacy-text">
            <br></br>
            Cesión de datos: Se cederán datos a colaboradores del responsable
            para la prestación del servicio, y asimismo, se autoriza
            expresamente la posible venta de los datos a terceros.
          </p>
          <p className="privacy-text">
            <br></br>
            Derechos: Podrá ejercitar los derechos de Acceso, Rectificación,
            Supresión, Oposición, Portabilidad y, en su caso Limitación, como se
            explica detalladamente en enlace Política de Privacidad.
          </p>
          <p className="privacy-text">
            <br></br>
            El Usuario acepta que el acceso y uso del Sitio tiene lugar libre y
            conscientemente, bajo su única responsabilidad, y se compromete a
            hacer un uso adecuado y lícito del Sitio de conformidad con la
            legislación aplicable y los Términos y Condiciones de Uso. Deberá
            abstenerte de hacer un uso no autorizado o fraudulento del Sitio,
            utilizarlo con fines o efectos ilícitos, ilegales o contrarios a lo
            establecido en la legislación aplicable y en los Términos y
            Condiciones de Uso.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE se reserva el derecho a actualizar, modificar o eliminar
            partes de los Términos y Condiciones de Uso, que serán comunicadas
            al Usuario con el nuevo acceso, en cuyo caso publicará las
            modificaciones en el Sitio e indicará la fecha de la última
            revisión. El Usuario es el responsable de visitar el Sitio
            regularmente y consultar tales actualizaciones.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE se reserva el derecho a denegar o retirar el acceso al
            Sitio y/o a los servicios, en cualquier momento y sin necesidad de
            preaviso a todos aquellos Usuarios que incumplan los Términos y
            Condiciones de Uso.
          </p>
          <br></br>
          <ol className="custom-list2">
            <li>¿Qué es SLEEPZZONE?</li>
            <li>Identificación de las partes</li>
            <li>Derechos de propiedad intelectual e industrial</li>
            <li>¿Qué puedes hacer con nuestra App?</li>
            <li>Productos y servicios</li>
            <li>Acceso y uso</li>
            <li>Registro</li>
            <li>Soporte y centro de ayuda</li>
            <li>Ley aplicable y jurisdicción</li>
          </ol>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">1. ¿Qué es SLEEPZZONE?</h1>
          <p className="privacy-text">
            SLEEPZZONE es la marca y nombre comercial del Sitio, titularidad de{" "}
            <strong>SLEEPZZONE LAND SL</strong>.
          </p>
          <p className="privacy-text">
            <br></br>
            SleepZZone (www.sleepzzone.com) es la Primera Plataforma de
            Telemedicina Personalizada del Sueño en Europa para empresas y
            empleados que, usando una Medical Wellness App, aplica IA con un
            Método SleepZZone propio para la Evaluación del Sueño qué afecta,
            según la OMS, al 50% de la población mundial después del COVID.
          </p>
          <p className="privacy-text">
            <br></br>
            En nuestra oferta de servicios SAAS B2B para empresas saludables
            diseñamos e implantamos Planes Corporativos del Sueño. Somos una
            solución, en un solo Click, para las personas qué duermen mal con un
            equipo multidisciplinar de profesionales.
          </p>
          <p className="privacy-text">
            <br></br>
            La Solución SleepZZone, permite a nuestros usuarios tener una
            Valoración con 13 perfiles de patologías del sueño, a través de una
            Medical Wellness App, única en el mundo por ofrecer estos servicios
            basada en la metodología elaborada por nuestro Consejo Asesor.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">2. Identificación de SLEEPZZONE</h1>
          <p className="privacy-text">
            <br></br>
            En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de
            la Sociedad de la Información y de Comercio Electrónico, SLEEPZZONE
            facilita a los Usuarios del Sitio los siguientes datos de
            información general:
          </p>
          <br></br>
          <ul className="custom-list">
            <li>Denominación social: SLEEPZZONE LAND SL</li>
            <li>Nombre comercial: SLEEPZZONE</li>
            <li>Número de Identificación Fiscal: B88586219</li>
            <li>Domicilio Social: CALLE ORFILA, 7 2 º. 28010, MADRID</li>
            <li>Email de contacto: info@sleepzzone.com</li>
            <li>Teléfono de contacto: 865 660 465 / 603 367 585</li>
            <li>
              Inscrita en el registro mercantil de Madrid, al tomo 40245, folio
              37, hoja número M-715027, inscripción 1ª.
            </li>
          </ul>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">
            3. Propiedad intelectual e industrial
          </h1>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE LAND SL es titular exclusivo o, en su caso, ostenta
            título legítimo suficiente de los derechos de propiedad intelectual
            e industrial sobre todos los contenidos del Sitio, entendiendo por
            éstos a título enunciativo, textos, fotografías, tecnología,
            software, hardware, links, dominios, nombres comerciales, signos
            distintivos, logotipos, marcas y demás contenidos audiovisuales o
            sonoros, así como su diseño gráfico, software, algoritmos y códigos
            fuente, correspondiéndole el ejercicio exclusivo de los derechos de
            explotación de los mismos, en cualquier forma, y en particular, de
            los derechos de reproducción, distribución, comercialización, I+D+i,
            comunicación pública y transformación.
          </p>
          <p className="privacy-text">
            <br></br>
            Cualquier uso indebido de tales elementos por persona diferente de
            su legítimo titular podrá ser perseguido de conformidad con la
            legislación vigente.
          </p>
          <p className="privacy-text">
            <br></br>
            Queda prohibida la reproducción, distribución, comercialización,
            copia, transformación, comunicación pública, cesión, difusión o
            cualquier otra actuación análoga o similar vulneradora de los
            derechos de propiedad intelectual e industrial, así como la
            modificación o eliminación de cualquier tipo, de todo o parte, de
            los contenidos obtenidos o accedidos del Sitio.
          </p>
          <p className="privacy-text">
            <br></br>
            El Usuario únicamente podrá realizar un uso privado y personal de
            los contenidos del Sitio, se obliga a utilizarlo de forma correcta y
            lícita, y no podrá, en ningún caso, establecer enlaces (“links”) en
            cualquier espacio accesible desde Internet y el Sitio.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">4. Exclusión de responsabilidad</h1>
          <p className="privacy-text">
            <br></br>
            El Sitio puede contener vínculos o enlaces a otros sitios web y
            aplicaciones de terceros. SLEEPZZONE no se hace responsable de los
            sitios web y aplicaciones de terceros a los que se acceda a través
            de Sitio, incluyendo la información contenida en los mismos.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE excluye cualquier responsabilidad por los daños y
            perjuicios de toda naturaleza que pudieran deberse o derivarse de la
            utilización del Sitio y que puedan estar causados por la falta de
            veracidad, exactitud, exhaustividad y/o autenticidad de la
            información que proporcione para la correcta prestación del servicio
            o utilización del Sitio y, en particular, por los daños y perjuicios
            de toda naturaleza que pudieren estar motivados por la suplantación
            de la personalidad de un tercero efectuada en su nombre en cualquier
            clase de comunicación realizada a través de SLEEPZZONE,
            incluyéndose, de igual forma, la exoneración por virus, malware o
            hackeo de cuentas o información.
          </p>
          <p className="privacy-text">
            <br></br>
            Ocasionalmente, el Sitio podrá estar no disponible temporalmente por
            motivos de mantenimiento, técnicos, de seguridad, de control, fallos
            de suministro eléctrico, u otras causas fundadas. SLEEPZZONE no
            asume ningún tipo de responsabilidad por errores, omisiones,
            interrupciones, eliminaciones, defectos, retrasos de funcionamiento
            o de transmisión, fallos de la línea de comunicaciones o
            alteraciones de las comunicaciones del Usuario.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE no será en ningún caso responsable del uso que el Usuario
            y/o terceros pudieran hacer del Sitio ni de los daños y perjuicios
            que pudieran derivarse del mismo.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE tiene suscritos acuerdos con centros, profesionales
            sanitarios y otro tipo de proveedores para ofrecer los servicios a
            aquellos usuarios que lo soliciten. SLEEPZZONE no será responsable
            de los retrasos, cumplimientos defectuosos, incumplimientos en la
            prestación del servicio cuando los mismos se debieran al
            incumplimiento del centro o profesional. El centro o profesional
            será el único responsable de cualquier obligación, derecho o
            resultado derivados de la prestación de sus servicios.
          </p>
        </section>
        <section className="privacy-section">
          <h1 className="privacy-title">
            5. ¿Qué puedes hacer en nuestra Web y App SLEEPZZONE?
          </h1>
          <p className="privacy-text">
            <br></br>
            Para utilizar el servicio SLEEPZZONE el Usuario debe tener acceso a
            Internet y un dispositivo compatible con SLEEPZZONE. El acceso y
            utilización del Sitio es público. El Usuario podrá navegar por el
            Sitio de manera gratuita, salvo en lo relativo al coste de la
            conexión a través de la red de telecomunicaciones suministrada por
            el proveedor de acceso por él contratado, y consumir nuestros
            servicios.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE ofrece servicios gratuitos y servicios de pago, para
            acceder a ellos es necesario que el Usuario se registre en el Sitio.
          </p>
        </section>
        <section className="privacy-section">
          <h1 className="privacy-title">6. Productos y servicios</h1>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE posibilita al Usuario la adquisición de productos y
            servicios relacionados con la salud bajo acuerdos con terceros, los
            cuales intervienen en diferentes partes del proceso.
          </p>
          <p className="privacy-text">
            <br></br>
            Alguno de los productos o servicios ofrecidos requiere del registro
            previo en el Sitio.
          </p>
          <p className="privacy-text">
            <br></br>
            Algunos de los servicios de los que actualmente dispone SLEEPZZONE,
            a título enunciativo y sin perjuicio de qué se puedan añadir nuevos,
            son los siguientes:
          </p>
          <br></br>
          <ul className="custom-list">
            <li>
              Buscador de servicios y productos médicos y soluciones (médicos y
              especialistas; clínicas y centros; información médica sobre
              enfermedades, pruebas y tratamientos).
            </li>
            <li>Generación de un perfil de salud.</li>
            <li>Servicios médicos de telemedicina: video consulta.</li>
          </ul>
          <p className="privacy-text">
            <br></br>
            Los servicios y productos ofertados, junto con las características
            principales de los mismos y su precio aparecen en pantalla.
            SLEEPZZONE podrá, en cualquier momento, añadir nuevos productos y/o
            servicios a los ofertados actualmente. Asimismo, SLEEPZZONE se
            reserva el derecho a retirar o dejar de ofrecer, en cualquier
            momento, y sin previo aviso, cualquiera de los productos y
            servicios.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE no permite la contratación de productos y/o servicios
            para su posterior reventa. Así, la acreditación que se obtiene con
            la adquisición del producto y/o prestación del servicio adquirido es
            personal e intransferible.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">7. Registro</h1>
          <p className="privacy-text">
            <br></br>
            Para la adquisición de ciertos productos y servicios y para
            determinadas funcionalidades del Sitio es necesario el registro
            previo del Usuario a través del formulario de alta en el área de
            cliente dispuesto al efecto. El registro es gratuito y podrá
            registrase cualquier persona mayor de edad.
          </p>
          <p className="privacy-text">
            <br></br>
            El Usuario se compromete a introducir datos veraces conforme a la
            legislación española, pudiendo ser residente en cualquier país del
            mundo. Desde el área de cliente podrán modificarse o actualizarse
            siempre que se desee o fuera necesario.
          </p>
          <p className="privacy-text">
            <br></br>
            Los servicios del Sitio que requieran registro sólo pueden ser
            utilizados por la persona que complete dicho proceso de registro. El
            Usuario registrado podrá dar de alta en su perfil a terceras
            personas. Los menores de 18 años no podrán utilizar los servicios
            del Sitio bajo registro propio si no es a través de un Usuario
            registrado del que dependan. Los datos propios o de terceras
            personas que el Usuario facilite están sujetos a la política de
            privacidad del Sitio.
          </p>
          <p className="privacy-text">
            <br></br>
            Los servicios y productos ofertados, junto con las características
            principales de los mismos y su precio aparecen en pantalla.
            SLEEPZZONE podrá, en cualquier momento, añadir nuevos productos y/o
            servicios a los ofertados actualmente. Asimismo, SLEEPZZONE se
            reserva el derecho a retirar o dejar de ofrecer, en cualquier
            momento, y sin previo aviso, cualquiera de los productos y
            servicios.
          </p>
          <p className="privacy-text">
            <br></br>
            Una vez registrado se enviará un correo electrónico de confirmación
            del registro con la finalidad de autenticar los datos que se hayan
            facilitado durante el proceso de registro.
          </p>
          <p className="privacy-text">
            <br></br>
            El Usuario registrado deberá tener en cuenta que es el único
            responsable de: (i) la calidad de los datos introducidos en el
            formulario, que deberán ser veraces, exactos y completos, y deberá
            mantener actualizados; (ii) la seguridad de su contraseña e
            identificación; y (iii) del uso de su perfil de Usuario.
          </p>
          <p className="privacy-text">
            <br></br>
            El Usuario se compromete a hacer un uso diligente y a no poner a
            disposición de terceros su nombre de Usuario y contraseña, así como
            a comunicar a SLEEPZZONE con la mayor brevedad, la pérdida, robo o
            cualquier riesgo de acceso a los mismos por un tercero.
          </p>
          <p className="privacy-text">
            <br></br>
            SLEEPZZONE podrá rechazar cualquier registro de Usuario o cancelar
            un perfil de Usuario, y por tanto rechazar la contratación de sus
            servicios, siempre que detecte que los datos facilitados son
            contrarios a la verdad; la solicitud no se ajusta a los Términos y
            Condiciones de Uso; tenga pendiente de pago algún servicio
            contratado con anterioridad con SLEEPZZONE; o se constate que el uso
            desarrollado por el Usuario pone en riesgo el funcionamiento del
            servicio de otro cliente, o tenga sospecha de un acceso no
            autorizado.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">
            8. Legislación aplicable y jurisdicción
          </h1>
          <p className="privacy-text ">
            Las presentes Condiciones de Uso, así como la relación entre
            SLEEPZZONE y el Usuario, se regirán e interpretarán con arreglo a la
            ley española Cualquier controversia que pudiera derivarse del acceso
            o utilización del Sitio se somete a los juzgados y tribunales que
            resulten competentes conforme a la legislación española.
          </p>
        </section>
      </div>
      <FooterSmall />
    </div>
  );
};

export default ConditionsPage;
