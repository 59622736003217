import React, { useEffect } from "react";
import Navbar from "../component/Navbar.js";
import Footer from "../component/Footer.js";
import CTA from "../component/CtaSec.js";
import FAQAccordion from "../component/Faq.js";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
export default function SleepBot() {
  const { t } = useTranslation();
  const isHomePage = false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://chat-component-svelte2.netlify.app/build/bundle.js";
    script.setAttribute("data-id", "x2nOIUYWBnhsWmSMHgiH");
    script.addEventListener("load", () => {});
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const faqItems = [
    {
      question: t("Sleepbot.q1"),
      answer: t("Sleepbot.a1"),
    },
    {
      question: t("Sleepbot.q2"),
      answer: t("Sleepbot.a2"),
    },
    {
      question: t("Sleepbot.q3"),
      answer: t("Sleepbot.a3"),
    },
    {
      question: t("Sleepbot.q4"),
      answer: t("Sleepbot.a4"),
    },
    {
      question: t("Sleepbot.q5"),
      answer: t("Sleepbot.a5"),
    },
    {
      question: t("Sleepbot.q6"),
      answer: t("Sleepbot.a6"),
    },
    {
      question: t("Sleepbot.q7"),
      answer: t("Sleepbot.a7"),
    },
    {
      question: t("Sleepbot.q8"),
      answer: t("Sleepbot.a8"),
    },
    {
      question: t("Sleepbot.q9"),
      answer: t("Sleepbot.a9"),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          SleepBot: Tu Compañero de Inteligencia Artificial para un Sueño
          Saludable - SleepZzone
        </title>
        <meta
          name="description"
          content="Descubre cómo SleepBot, tu compañero de inteligencia artificial, puede ayudarte a mejorar la calidad de tu sueño. Obtén consejos y seguimiento personalizado para un descanso reparador, respaldado por médicos y científicos."
        />
        <meta
          name="keywords"
          content="SleepBot, Inteligencia artificial, Sueño saludable, Consejos de sueño, Seguimiento de sueño"
        />
      </Helmet>
      <Navbar isHomePage={isHomePage} whiteHeader={true} />
      <section className="banner-sec relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="grid relative z-10 lg:grid-cols-2 pt-32 pb-16 lg:pt-48 lg:pb-32">
            <div className="lg:w-[562px]">
              <h2 className="relative mb-3 z-10 font-black text-white text-3xl lg:text-5xl">
                {t("Sleepbot.titulo")}
              </h2>

              <p className="opacity-70 text-xl text-white leading-relaxed">
                {t("Sleepbot.subtitulo")}
              </p>
              <a
                href="#"
                class="rounded-lg inline-block text-base leading-[48px] bg-white filled-btn mt-8 h-[48px] px-6 font-black mi-enlace"
              >
                {t("Sleepbot.boton")}
              </a>
              <div class="texto-oculto">{t("Sleepbot.advertencia")}</div>
            </div>
            <div className="sleepbot-banner-image text-center mb-8 lg:mb-0 lg:text-right order-first lg:order-last">
              <img
                className="rounded-[40px] inline-block"
                src="/images/sleepbot-banner-image.svg"
                alt="imagen de logo de robot de sleepbot"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="border-gradiant"></div>
      <section className="employee-sec py-12 lg:py-24">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="grid lg:grid-cols-3 gap-8">
            <div className="col-cus-3 text-center">
              <h4 className="text-white text-xl	font-black">
                {t("Sleepbot.nivel1")}
              </h4>
              <p className="text-white opacity-70 mt-2 mb-5">
                {t("Sleepbot.textoNivel1")}
              </p>
              <a
                rel="/sleepbot"
                className="blue-btn text-white rounded-lg inline-block  text-base	 leading-[48px]  h-[48px] px-6 font-black"
              >
                {t("Sleepbot.botonNivel1")}
              </a>
            </div>
            <div className="col-cus-3 text-center">
              <h4 className="text-white text-xl	font-black">
                {t("Sleepbot.nivel2")}
              </h4>
              <p className="text-white opacity-70 mt-2 mb-5">
                {t("Sleepbot.textoNivel2")}
              </p>
              <a
                href="/consultationspage"
                className="blue-btn text-white rounded-lg inline-block  text-base	 leading-[48px]  h-[48px] px-6 font-black"
              >
                {t("Sleepbot.botonNivel2")}
              </a>
            </div>
            <div className="col-cus-3 text-center">
              <h4 className="text-white text-xl	font-black">
                {t("Sleepbot.nivel3")}
              </h4>
              <p className="text-white opacity-70 mt-2 mb-5">
                {t("Sleepbot.textoNivel3")}
              </p>
              <a
                href="/consultations"
                className="blue-btn text-white rounded-lg inline-block  text-base	 leading-[48px] min-w-[180px]  h-[48px] px-6 font-black"
              >
                {t("Sleepbot.botonNivel3")}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-sec bg-neutral-100 lg:mb-20 relative py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="faq-inner max-w-[800px] mx-auto">
            <h2 className="relative z-10 font-black text-5xl text-center mb-12">
              FAQs
            </h2>
            <FAQAccordion items={faqItems} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
